/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { Component } from "react"

// This ensures that the icon CSS is loaded immediately before attempting to render icons
import "@fortawesome/fontawesome-svg-core/styles.css"
import { config } from "@fortawesome/fontawesome-svg-core"
import { faTwitter } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ParticleBackground } from "./particle-background"

import PropTypes from "prop-types"

import { Header } from "./header"
import "../styles/defaults.scss"

import {
    layout,
    header,
    container,
    mainPage,
    pageFooter,
    divider,
    socialLink,
    icon
} from "./layout.module.scss"
import ReactTooltip from "react-tooltip"

// Prevent fontawesome from dynamically adding its css since we did it manually above
config.autoAddCss = false


class Layout extends Component {
    constructor(props) {
        super(props)
        this.state = { isClient: false }
    }

    render() {
        return (
            <React.Fragment key={this.state.isClient}>
                <div className={layout}>
                    <div className={header}>
                        <Header siteTitle={"Thomas Winters"}/>
                    </div>
                    <div className={container}>
                        <main className={mainPage}>{this.props.children}</main>
                        <footer className={pageFooter}>
                            <span>Thomas Winters</span>
                            <span className={divider}> – </span>
                            <a href={"https://twitter.com/thomas_wint"}
                               target="_blank"
                               rel="noopener noreferrer"
                               className={socialLink}>
                                <FontAwesomeIcon icon={faTwitter} className={icon}/>
                                @thomas_wint
                            </a>
                        </footer>
                    </div>
                    <ReactTooltip effect={"solid"}
                                  backgroundColor={"#141821"}/>
                </div>

                <ParticleBackground/>
            </React.Fragment>
        )
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        ReactTooltip.rebuild()
    }

    componentDidMount() {
        this.setState({ isClient: true })
    }
}

Layout.propTypes = {
    children: PropTypes.node.isRequired
}

export default Layout
