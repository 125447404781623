// extracted by mini-css-extract-plugin
export var allLinks = "header-module--all-links--b2dd1";
export var backgroundColor = "#141821";
export var borderRadius = ".15rem";
export var followText = "header-module--follow-text--904d9";
export var headerCls = "header-module--header-cls--8bcf7";
export var icon = "header-module--icon--f14cf";
export var maxModalWidth = "48em";
export var menuButton = "header-module--menu-button--72bb1";
export var modalBackgroundColor = "rgba(20,24,33,.75)";
export var name = "header-module--name--4066b";
export var navigation = "header-module--navigation--2fde5";
export var navigationButton = "header-module--navigation-button--e28c0";
export var open = "header-module--open--10a41";
export var popMenu = "header-module--pop-menu--c0ae4";
export var popMenuContent = "header-module--pop-menu-content--2d9af";
export var siteTitle = "header-module--site-title--5440a";
export var socialLinks = "header-module--social-links--2ab62";