import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import {
    contentsList
} from "./table-of-contents.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBookmark, faCaretRight } from "@fortawesome/free-solid-svg-icons"
import { headerIcon } from "../pages/appearances.module.scss"

const ContentsList = ({ items }) => {
    return <ul className={"fa-ul " + contentsList}>
        {items.map(item => <li key={item.url}>
            <FontAwesomeIcon icon={item.icon || faCaretRight}
                             size={item.iconSize || "1x"}
                             listItem/>
            <Link to={item.url}>
                <span>{item.title}</span>
            </Link>
            {item.items && item.items.length && <ContentsList items={item.items}/>}
        </li>)}
    </ul>
}

const TableOfContents = ({ tableOfContents }) => {
    return <section id={"table-of-contents"}>
        <h2>
            <FontAwesomeIcon icon={faBookmark} className={headerIcon} size={"sm"}/>
            Table of Contents
        </h2>
        {<ContentsList items={tableOfContents.items}/>}
    </section>
}
TableOfContents.propTypes = {
    tableOfContents: PropTypes.object
}
export default TableOfContents
