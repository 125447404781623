import React from "react"
import Particles from "react-tsparticles";

import { particles } from "./particle-background.module.scss"

const particlesConfig ={
    "fpsLimit": 15,
    "particles": {
        "number": {
            "value": 80,
            "density": {
                "enable": true,
                "value_area": 800
            }
        },
        "color": {
            // "value": "#40434b"
            "value": "#ffffff"
        },
        "shape": {
            "type": "circle",
            "stroke": {
                "width": 0,
                "color": "#000000"
            },
            "polygon": {
                "nb_sides": 5
            },
            "image": {
                "src": "img/github.svg",
                "width": 100,
                "height": 100
            }
        },
        "opacity": {
            "enabled":false,
            // "value": 1,
            "value": 0.2,
            "random": false,
            "anim": {
                "enable": false,
                "speed": 1,
                "opacity_min": 1,
                // "opacity_min": 0.1,
                "sync": false
            }
        },
        "size": {
            "value": 3.945738208161363,
            "random": true,
            "anim": {
                "enable": false,
                "speed": 40,
                "size_min": 0.1,
                "sync": false
            }
        },
        "line_linked": {
            "enable": false,
            "distance": 150,
            "color": "#ffffff",
            "opacity": 0.4,
            "width": 1
        },
        "move": {
            "enable": true,
            "speed": .3,
            "random": true,
            "straight": false,
            "out_mode": "out",
            "bounce": false,
            "attract": {
                "enable": false,
                "rotateX": 315.65905665290904,
                "rotateY": 315.65905665290904
            }
        }
    },
    "interactivity": {
        "detect_on": "window",
        "events": {
            "onhover": {
                "enable": true,
                "mode": "bubble"
            },
            "onclick": {
                "enable": true,
                "mode": "push"
            },
            "resize": true
        },
        "modes": {
            "grab": {
                "distance": 400,
                "line_linked": {
                    "opacity": 1
                }
            },
            "bubble": {
                "distance": 100,
                // "size": 7,
                "size": 4,
                "duration": 8,
                // "opacity": 1,
                "opacity": 0.65,
                "speed": 3
            },
            "repulse": {
                "distance": 5,
                "duration": 3
            },
            "push": {
                // "particles_nb": 10,
                "particles_nb": 5,
            },
            "remove": {
                "particles_nb": 2
            }
        }
    },
    "retina_detect": true
}

const ParticleBackground = () => (
    <Particles
        className={particles}
        params={particlesConfig}
    />
)

export { ParticleBackground }



