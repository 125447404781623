// extracted by mini-css-extract-plugin
export var backgroundColor = "#141821";
export var borderRadius = ".15rem";
export var container = "layout-module--container--46809";
export var divider = "layout-module--divider--882d3";
export var header = "layout-module--header--6a868";
export var icon = "layout-module--icon--5bfd2";
export var layout = "layout-module--layout--260ed";
export var mainPage = "layout-module--main-page--f4f34";
export var maxModalWidth = "48em";
export var modalBackgroundColor = "rgba(20,24,33,.75)";
export var pageFooter = "layout-module--page-footer--e399a";
export var socialLink = "layout-module--social-link--c51ff";