import React from "react"
import {
    threadContainer,
    threadContainerInner,
    twitterThreadTweet,
    tweetContainer,
    tweetContainerInner
} from "./tweet.module.scss"

const Tweet = ({ children }) => {
    if (children && children.length > 1) {
        return (
            <div className={threadContainer}>
                <div className={threadContainerInner}>
                    {children.map((c, index) =>
                        <div key={index} className={twitterThreadTweet}>
                            {c}
                        </div>
                    )}

                </div>
            </div>
        )
    } else {

        return (
            <div className={tweetContainer}>
                <div className={tweetContainerInner}>
                    {children}
                </div>
            </div>
        )
    }
}


export default Tweet
